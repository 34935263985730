<template>
  <a-layout id="guest-onboarding-layout">
    <a-layout-header class="guest-onboarding-header">
      <div class="logo">
        <img src="/partners/sims-logo-new.png" alt="Logo" />
      </div>
      <div class="right">
        <a-button style="margin-right: 5px;" @click.prevent="createTrialAccount" size="large" type="link">
          <a-icon type="user"></a-icon> Create Trial Account</a-button>

        <a-button style="margin-right: 5px;" @click.prevent="bookDemo" size="large" type="link">
          <a-icon type="message"></a-icon>
          Book Demo</a-button>

        <share-with-colleague-button-and-modal style="margin-right: 20px;"></share-with-colleague-button-and-modal>

        <!-- <a-button v-if="!isTestingOrganisation" class="btn-rounded" size="large" type="primary" @click="goToSetup">Click
          Here To Start 60 Day Free Trial</a-button>
        <a-button v-else disabled class="btn-rounded" size="large" type="primary">You are using a SIMS test
          organisation</a-button> -->
        <!-- <a-button @click.prevent="openIntercom" class="btn-rounded" size="large" type="primary">
          <a-icon type="message"></a-icon>
          Any Questions?</a-button> -->

        <!-- <a-button v-if="!isTestingOrganisation" @click.prevent="openIntercom" class="btn-rounded"
          style="margin-left: 15px;" size="large" type="primary">
          <a-icon type="check"></a-icon>
          Try Now</a-button>
        <a-button v-else disabled class="btn-rounded" size="large" type="primary">You are using a SIMS test
          organisation</a-button> -->
      </div>
    </a-layout-header>
    <a-layout-content class="onboarding-body"
      :style="{ background: '#5E22E2!important', padding: '0', overflow: 'auto' }">

      <div class="cal-body-inner">
        <div class="header">
          <a-row :gutter="120" type="flex" align="middle">
            <a-col :span="10">
              <h1>Introducing teamSOS</h1>
              <p>
                <!-- The incident response app with innovative 2-way comms tools that accelerate resolution whilst -->
                <!-- automatically evidencing & supporting staff in-the-moment. -->
                A GDPR-compliant platform for on-call incident management and response, enabling incident recording and
                alerts without relying on radios, texts, or phone calls.
              </p>

              <a href="#book-demo" class="book-demo-btn">Learn More</a>

            </a-col>
            <a-col :span="14">
              <img src="@/assets/sims-right.png" />
            </a-col>
          </a-row>
        </div>


        <div class="divider"></div>

        <div class="main-features" id="book-demo">

          <div class="inner">
            <a-row :gutter="50">
              <a-col :span="12">

                <div class="from-founders">
                  <img src="@/assets/from-founders.png" alt="">
                </div>

                <a-collapse v-model="activeKey" accordion>
                  <a-collapse-panel key="1" header="Crisis & Lockdowns">
                    <p>
                      teamSOS is the ultimate toolkit for schools to manage crisis situations with unparalleled speed &
                      efficiency, while complying with the DfE emergency response provisions.
                    </p>
                    <p>
                      Whether it’s a fire, intruder, or bomb threat, staff are alerted within seconds. Automatic
                      escalation acts as a failsafe to ensure emergency reports are addressed quickly. Real-time
                      communication tools such as live audio broadcasts & voice-to-text offer dynamic and appropriate
                      channels for staff coordination - with the ability to communicate silently during lockdowns.
                    </p>
                  </a-collapse-panel>
                  <a-collapse-panel key="2" header="Replacing Radios">
                    <p>
                      teamSOS revolutionises traditional communication methods within schools by allowing every staff
                      member to raise and participate in incident-specific channels with a suite of innovative tools
                      including live audio broadcasting, voice-to-text dictation & chat messaging.
                    </p>
                    <p>
                      All communications are captured in instant & comprehensive reports, saving staff time whilst
                      providing playback features that traditional radios can’t offer. teamSOS provides insight for SLT
                      to identify patterns and trends, enabling data-driven decisions to improve school safety and
                      efficiency. Unlike radios, teamSOS is accessible on any device, has unlimited range, and does not
                      require costly and outdated equipment.
                    </p>
                  </a-collapse-panel>
                  <a-collapse-panel key="3" header="Behaviour & Attendance">
                    <p>
                      Empower staff with the ability to alert and mobilise on-call teams within seconds from our app,
                      available on any device, or directly from the call-for-help panel in SIMS.
                    </p>
                    <p>
                      Quicker intervention and support prevent behavioural incidents from escalating, serving as a
                      powerful deterrent to students. Increase staff confidence knowing they will be heard when they
                      need advice, back-up, or urgent SLT intervention.
                    </p>
                  </a-collapse-panel>
                  <a-collapse-panel key="4" header="CPOMS Integration">
                    <p>
                      Integration with CPOMS allows staff to quickly and effectively report safeguarding concerns to the DSL for a fast and reliable safeguarding response.
                    </p>
                    <p>
                      Safeguarding concerns raised in teamSOS are automatically added to the student record in CPOMS to provide a 360 view of the child in the system.
                    </p>
                  </a-collapse-panel>
                </a-collapse>

              </a-col>
              <a-col :span="12">

                <!-- Calendly inline widget begin -->
                <div class="calendly-inline-widget" data-url="
https://calendly.com/teamsos" style="min-width:320px;height:700px;"></div>
                <!-- Calendly inline widget end -->

              </a-col>
            </a-row>
          </div>



        </div>


        <div class="divider-2"></div>

        <div class="phil-neal">
          <div class="inner">
            <a-row :gutter="100">
              <a-col :span="14">
                <div class="left">
                  <img src="@/assets/phil-neal.png" alt="">

                  <div style="position: relative; width: 100%; height: 0; padding-top: 56.2500%;
 padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); margin-top: 1.6em; margin-bottom: 0.9em; overflow: hidden;
 border-radius: 8px; will-change: transform;"> <iframe loading="lazy"
                      style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"
                      src="https://www.canva.com/design/DAF6JLI4aIw/watch?embed" allowfullscreen="allowfullscreen"
                      allow="fullscreen"> </iframe></div>
                </div>
              </a-col>
              <a-col :span="10">
                <div class="right">
                  <img class="phil-neal-qa" src="@/assets/phil-neal-qa.png" alt="">

                  <h2>Phil Neal, Creator of SIMS, visits Sybil Andrews Academy and asks the hard hitting questions about
                    the impact of teamSOS</h2>

                  <a href="https://teamsos.co.uk/phil-neal-sims-talks-to-school-about-teamsos/" target="_blank" class="article-btn">Read Phil's Article</a>
                </div>
              </a-col>
            </a-row>
          </div>
        </div>
      </div>

    </a-layout-content>
  </a-layout>
</template>

<script>
import { mapGetters } from 'vuex';
// import GuestOnboardingFeatures from './Landing/GuestOnboardingFeatures.vue';
// import GuestOnboardingWhatWeDo from './Landing/GuestOnboardingWhatWeDo.vue';
// import GuestOnboardingPricing from './Landing/GuestOnboardingPricing.vue';
// import GuestSuccessStories from './Landing/GuestSuccessStories.vue';
// import GuestOnboardingImpact from './Landing/GuestOnboardingImpact.vue';
import posthog from 'posthog-js';
import ShareWithColleagueButtonAndModal from './Landing/ShareWithColleagueButtonAndModal.vue';

export default {
  mounted() {
    // Add calendly widget.js
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  },

  components: {
    // GuestOnboardingPricing,
    // GuestOnboardingWhatWeDo,
    // GuestOnboardingImpact,
    ShareWithColleagueButtonAndModal
  },

  computed: {
    ...mapGetters('missionControl', {
      isLoggedIn: 'isLoggedIn'
    }),

    ...mapGetters('guestOnboarding', {
      isTestingOrganisation: 'isTestingOrganisation'
    })
  },

  data() {
    return {
      activeKey: 1
    }
  },

  created() {
    // If posthog initialised
    if (window.POSTHOG_INITIALISED) {
      posthog.capture('onboarding_welcome_page');
    }

    if (this.isLoggedIn) {
      this.$message.info('You cannot access this page while logged in');
      this.$router.push('/');
    } else {
      this.bootIntercom();
    }
  },

  methods: {
    bootIntercom() {
      this.$intercom.boot({});
    },

    createTrialAccount() {
      this.$router.push('/guest-onboarding/create-account');
    },

    bookDemo() {
      document.getElementById('book-demo').scrollIntoView({ behavior: 'smooth' });
    },

    openIntercom() {
      this.$intercom.show();
    },

    openCalendly() {
      window.Calendly.initPopupWidget({ url: 'https://calendly.com/teamsos' });
    },

    goToSetup() {
      this.$router.push({
        path: '/guest-onboarding/create-account',
        query: this.$route.query
      });
    },

    previousStage() {
      if (this.currentStage > 0) {
        this.currentStage--;
      }
    },

    nextStage() {
      this.currentStage++;
    }
  }
}
</script>

<style lang="scss">
#guest-onboarding-layout {
  // Smooth scroll

  // min-height: 100vh;
  // background: #3A0BA3;
  // background: rgb(247, 248, 250);
  background: #5E22E2 !important;
  overflow-y: scroll !important;
  height: 100vh;
  max-height: 100vh;
  color: #fff;
  // new

  .onboarding-body {
    height: calc(100vh - 65px);
    display: flex;
    flex-direction: column;

    scroll-behavior: smooth;

    .cal-body-inner {
      .header {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 80px;
        padding-bottom: 80px;

        text-align: left;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;

        h1 {
          color: #fff;
          font-size: 2.8em;
          line-height: 1.3;
          margin-bottom: 30px;
        }

        p {
          color: #fff;
          font-size: 1.3em;
          font-weight: 300;
          line-height: 1.5;
          margin-bottom: 45px;
        }

        .book-demo-btn {
          color: #FFFFFF !important;
          border-width: 0px !important;
          border-color: RGBA(0, 0, 0, 0);
          border-radius: 66px;
          font-size: 18px;
          background-color: #f72585;
          transition: border 300ms ease 0ms;
          box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
          text-shadow: none;
          text-decoration: none;
          font-weight: 500;
          padding: .5em 1.5em;
          line-height: 1.7em !important;
          background-size: cover;
          background-position: 50%;
          background-repeat: no-repeat;
          border: 2px solid;

          &:hover {
            border-color: #F72585 !important;
            padding-right: 2.5em;
            padding-left: 0.9em;

            &::after {
              opacity: 1;
              display: inline-block;
              margin-left: 0.3em;
            }
          }

          &::after {
            font-size: 1.2em;
            opacity: 0;
            display: inline-block;
            content: "→";
            font-style: normal;
            font-weight: 400;
            line-height: 1.5em;
            -webkit-font-smoothing: antialiased;
            text-shadow: 0 0;
            position: absolute;
            margin-left: -0.1em;
            transition: all .2s;
            color: #FFFFFF !important;

          }
        }

        img {
          max-width: 100%;
        }
      }

      .divider {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiM1ZTIyZTIiPjxwYXRoIGQ9Ik0wIDB2NjBzNTczLjA5IDgwIDEyODAgODBWMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAwdjMwczU3My4wOSAxMTAgMTI4MCAxMTBWMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAwczU3My4wOSAxNDAgMTI4MCAxNDBWMHoiLz48L2c+PC9zdmc+);
        background-color: #3A0CA3;
        background-size: 100% 100px;
        // top: 0;
        height: 100px;
        width: 100%;
        z-index: 1;
        transform: scale(1, 1);
        display: block;
        // position: absolute;
        pointer-events: none;
        width: 100%;
        // left: 0;
        // right: 0;
      }

      .divider-2 {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiM1ZTIyZTIiPjxwYXRoIGQ9Ik0wIDB2NjBzNTczLjA5IDgwIDEyODAgODBWMHoiIGZpbGwtb3BhY2l0eT0iLjMiLz48cGF0aCBkPSJNMCAwdjMwczU3My4wOSAxMTAgMTI4MCAxMTBWMHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMCAwczU3My4wOSAxNDAgMTI4MCAxNDBWMHoiLz48L2c+PC9zdmc+);
        background-color: #3A0CA3;
        background-size: 100% 100px;
        // top: 0;
        height: 100px;
        width: 100%;
        z-index: 1;
        transform: scale(1, 1);
        display: block;
        pointer-events: none;
        width: 100%;

        // Flip vertically and horizontally
        transform: scaleY(-1) scaleX(-1);
      }

      .main-features {
        position: relative;

        padding-top: 70px;
        background: #3A0CA3 !important;

        .from-founders {
          text-align: center;

          img {
            max-width: 350px;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 60px;
          }
        }

        .inner {
          max-width: 1200px;
          margin-left: auto;
          margin-right: auto;
        }

        .ant-collapse {
          border: 1px solid #3A0BA3;
          background-color: #3A0BA3;
        }

        .ant-collapse>.ant-collapse-item>.ant-collapse-header {
          background: #3A0BA3;
          color: #fff;
          font-weight: 500;
          font-size: 16px;
        }

        .ant-collapse>.ant-collapse-item {
          border-bottom: 1px solid #3A0BA3;
        }

        .ant-collapse-content {
          background: #5155EA !important;
          color: #fff;
          // color: var(--text-color-level2, rgba(26, 26, 26, 0.61)) !important;
        }
      }

      .phil-neal {
        padding-top: 60px;
        padding-bottom: 60px;

        .inner {
          max-width: 1200px;
          margin-left: auto;
          margin-right: auto;

          .left {

            // text-align: center;
            img {
              max-width: 80%;
              margin-bottom: 25px;
              margin-left: auto;
              margin-right: auto;
            }
          }

          .right {
            .phil-neal-qa {
              max-width: 200px;
            }

            h2 {
              color: #fff;
              margin-top: 40px;
              font-weight: 400;
              margin-bottom: 40px;
            }

            .article-btn {
              color: #FFFFFF !important;
              border-width: 0px !important;
              border-color: RGBA(0, 0, 0, 0);
              border-radius: 66px;
              font-size: 18px;
              background-color: #360C79;
              transition: border 300ms ease 0ms;
              box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
              text-shadow: none;
              text-decoration: none;
              font-weight: 500;
              padding: .5em 1.5em;
              line-height: 1.7em !important;
              background-size: cover;
              background-position: 50%;
              background-repeat: no-repeat;
              border: 2px solid;

              &:hover {
                border-color: #360C79 !important;
                padding-right: 2.5em;
                padding-left: 0.9em;

                &::after {
                  opacity: 1;
                  display: inline-block;
                  margin-left: 0.3em;
                }
              }

              &::after {
                font-size: 1.2em;
                opacity: 0;
                display: inline-block;
                content: "→";
                font-style: normal;
                font-weight: 400;
                line-height: 1.5em;
                -webkit-font-smoothing: antialiased;
                text-shadow: 0 0;
                position: absolute;
                margin-left: -0.1em;
                transition: all .2s;
                color: #FFFFFF !important;

              }
            }
          }
        }
      }
    }
  }

  .getting-started-btn {
    width: 100%;
    padding: 25px;
    line-height: 0px;
    margin-top: 15px;
  }

  .guest-onboarding-header {
    background: #3A0BA3;
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;

    height: 65px;
    line-height: 65px;

    .right {
      float: right;
      // margin-right: 20px;

      .ant-btn-link {
        color: #fff;
      }
    }

    .logo {
      float: left;
      height: 40px;

      img {
        max-height: 100%;
      }
    }
  }

  .testimonial {
    margin-top: 35px;
    display: flex;

    // color: #fff;
    font-family: 'Quicksand';

    .left {
      flex-shrink: 1;
      display: flex;
      align-items: center;

      img {
        height: 50px;
      }
    }

    .right {
      flex-grow: 1;
      padding-left: 5px;
      text-align: right;

      font-size: 17px;

      .from {
        font-size: 12px;
        margin-top: 5px;
      }
    }
  }

  .getting-started {
    text-align: center;
    margin-top: 50px;

    p {
      margin-bottom: 80px;
      display: block;
    }

    a {
      color: #fff;
      padding: 15px 40px;
      border-radius: 25px;
      font-family: 'Quicksand';
      border: 2px solid #fff;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #3A0BA3;
        background: #fff;
      }
    }

    .getting-started-bottom {
      position: relative;

      .free-trial-text {
        position: absolute;
        height: 43px;
        top: -100%;
      }
    }
  }

  .features-section {
    padding-top: 100px;
    padding-bottom: 40px;

    .left-col {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      .cta-wrapper {
        padding-top: 30px;
      }

      a {
        color: #fff;
        padding: 15px 40px;
        width: 100%;
        border-radius: 25px;
        font-family: 'Quicksand';
        border: 2px solid #fff;
        font-weight: 500;
        transition: all 0.2s ease-in-out;
        margin-left: 10px;
        margin-right: 10px;

        &:hover {
          color: #3A0BA3;
          background: #fff;
        }
      }
    }

    p {
      font-size: 16px;
      color: #fff;
      font-family: 'Quicksand';
      font-weight: 400;
      line-height: 2;
      margin-bottom: 20px;
    }

    .phone-image {
      width: 100%;
    }
  }

  .second-features-section {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 120px;

    img {
      margin-left: auto;
      margin-right: auto;
    }

    .cta-wrapper {
      padding-top: 80px;
    }

    a {
      color: #fff;
      padding: 15px 40px;
      border-radius: 25px;
      font-family: 'Quicksand';
      border: 2px solid #fff;
      font-weight: 500;
      transition: all 0.2s ease-in-out;
      margin-left: 10px;
      margin-right: 10px;

      &:hover {
        color: #3A0BA3;
        background: #fff;
      }
    }
  }
}
</style>